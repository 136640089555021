#vid {
    position: fixed;
    border-radius: 50%;
    max-height: 200px;
    height: auto;
    left: 0;
    right: 0;
    margin: auto;
    top: 10%;
    z-index: 9999;
    box-shadow: 6px 6px 7px 0 rgb(174 174 192 / 78%), -6px -6px 7px 0 #FFF;

}
